<template>
  <b-card-normal title="Dil Ayarları" class="rounded-0" :showLoading="show">
    <template v-slot:body>
      <b-form @submit.stop.prevent="onSubmit" @reset.prevent="resetForm" autocomplete="off">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group :label="$t('global.aktifDiller')" description="Aktif Olacak Dilleri Belirleyiniz.">
              <v-select
                v-model="form.diller"
                :options="sabit_diller"
                :reduce="(sabit) => sabit.lang"
                label="title"
                multiple
                :clearable="false"
                @input="handlerDilChange"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Varsayılan" description="Varsayılan Dili Belirleyiniz.">
              <v-select
                v-model="form.varsayilan"
                :options="form.diller"
                :reduce="(dil) => dil.lang"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pt-1">
          <b-col cols="12" md="6" lg="6">
            <b-button squared block type="submit" :disabled="!firmaKNo" variant="primary">
              <feather-icon icon="CheckSquareIcon" class="mr-50" />
              <span class="align-middle">{{ form._id == null ? $t('global.kaydet') : $t('global.guncelle') }}</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6" lg="6">
            <b-button squared block>
              <feather-icon icon="XSquareIcon" class="mr-50" :disabled="!firmaKNo" />
              <span class="align-middle">{{ $t('global.iptal') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </b-card-normal>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
import vSelect from 'vue-select';

import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { ref, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
import { diller } from '@/libs/languages';
export default {
  components: {
    BCardNormal,
    vSelect,
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.show = ref(false);
    expo.form = ref({
      _id: null,
      diller: [],
      varsayilan: null,
    });
    expo.sabit_diller = ref(diller);

    const handlerConfig = async () => {
      expo.show.value = true;
      await store.dispatch('dilAyarlariGetir').then((res) => {
        if (res.data.data !== null) {
          expo.form.value = res.data.data;
        }
      });
      expo.show.value = false;
    };

    handlerConfig();

    expo.handlerDilChange = (event) => {
      let selectLang = [];
      event.forEach((element) => {
        selectLang.push(diller.find((x) => x.lang == element));
      });
      expo.form.value.diller = selectLang;
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      expo.show.value = true;
      store
        .dispatch('dilAyarlariEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            expo.show.value = false;
          }
        })
        .catch((err) => {
          expo.show.value = false;
          if (err.success == false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Hata`,
                  icon: 'CheckSquareIcon',
                  variant: 'danger',
                  text:
                    Object.keys(err.data.dup).length > 0
                      ? Object.keys(err.data.dup)[0] + ' ' + err.data.message
                      : err.data.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
